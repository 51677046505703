import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  getCourseNavigation,
  getCourseProgress,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import AudioPlayer from "../../../../../components/audio-player"
import BackgroundBox from "../../../../../components/background-box"
import Box from "../../../../../components/box"
import Caption from "../../../../../components/caption"
import Constrain from "../../../../../components/constrain"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Image from "../../../../../components/image"
import Note from "../../../../../components/note"
import NoBreak from "../../../../../components/no-break"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "der-rhein",
}

const Page = () => {
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)

  const data = useStaticQuery(graphql`
    query RheinQuery {
      rhein: file(relativePath: { eq: "kurse/klemm-rhein.jpg" }) {
        ...largeImage
      }
    }
  `)

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/reisekosten" />
      }
      navigation={navigation}
      progress={progress}
    >
      <Seo title="Hölderlins Liebe zum Wasser" />
      <Box>
        <Stack>
          <Heading as="h2" level={2}>
            Hölderlins Liebe zum Wasser
          </Heading>
          <Paragraph>
            Dass Hölderlin auf seiner Reise gleich mehrmals den Rhein sah, war
            für ihn ein echtes Ereignis. Mit dem Wasser ist Hölderlin
            aufgewachsen: Er wurde in Lauffen am Neckar geboren und hat seine
            Kindheit in Nürtingen verbracht, wo er mit seinen Geschwistern am{" "}
            <NoBreak>Neckarstrand</NoBreak> gespielt hat. Doch der große, breite
            Rhein war für Hölderlin etwas Neues, Beeindruckendes.
          </Paragraph>
          <Note variant="task">
            Betrachte dieses Bild vom Rhein einmal genauer und hör dir darunter
            an, was Hölderlin über den Fluss gesagt hat.
          </Note>
          <Image
            image={data.rhein.childImageSharp.gatsbyImageData}
            alt="Rhein"
            caption="Ein Foto aus der Ausstellung ›Hölderlins Orte – Fotografien von Barbara Klemm‹"
            attribution="Barbara Klemm"
          />
          <Constrain align="right">
            <Stack>
              <BackgroundBox>
                <Stack space={6}>
                  <Paragraph italic>
                    Man denke sich, der majestätischruhige Rhein, so weit her,
                    daß man die Schiffe kaum noch bemerkte – so weit hinaus, daß
                    man ihn fast für eine blaue Wand ansehen könnte, und am
                    gegenseitigen Ufer dicke, wilde Wälder [...] da lud man
                    Schiffe aus – dort stießen andere ins Meer, und der
                    Abendwind bließ in die schwellende Seegel...
                  </Paragraph>
                  <Caption align="left">Friedrich Hölderlin</Caption>
                </Stack>
              </BackgroundBox>
              <AudioPlayer src={`kurse/hoelderlins-reisen/der-rhein`} />
            </Stack>
          </Constrain>

          <Paragraph>
            Dass Hölderlin die Flüsse und das Meer so sehr mochte, merkt man
            auch in seinen Gedichten. Besonders häufig hat er über den Rhein
            geschrieben. Eines seiner Gedichte über diesen Fluss hat 15 Strophen
            und 222 Verse. Es ist eines der berühmtesten Gedichte von Hölderlin.
          </Paragraph>
          <Note>
            Magst du das Wasser auch so sehr wie Hölderlin? Oder bist du lieber
            in den Bergen unterwegs oder spielst im Wald?
          </Note>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
