import React from "react"
import PropTypes from "prop-types"
import { alpha } from "@theme-ui/color"

import Box from "./box"
import backgroundMetrik from "../images/bg-metrik.jpg"

const BackgroundBox = ({ p, children, ...restProps }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url("${backgroundMetrik}")`,
        display: "flex",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <Box
        height="100%"
        p={p}
        sx={{
          bg: alpha("muted", 0.95),
        }}
        {...restProps}
      >
        {children}
      </Box>
    </Box>
  )
}

BackgroundBox.propTypes = {
  p: PropTypes.array,
}

BackgroundBox.defaultProps = {
  p: [6, 6, 12],
}

export default BackgroundBox
